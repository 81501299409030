import type { InferProps } from 'prop-types';
import type { ComponentClass } from 'react';
import { connect } from 'react-redux';

import { appStateReset, logout } from '@redux/actions/action-auth';
import { updateAccountContext } from '@redux/actions/action-system';
import { setTopnavViewProps } from '@redux/actions/action-topnav';
import {
  setWhatsNewDialog,
  setWhatsNewShowCtrl,
} from '@redux/actions/action-whatsnew';
import { showModal } from '@redux/modals.slice';
import type { RootState } from '@redux/store';
import { selectSystemStatus } from '@redux/system/health.slice';
import { setViewState } from '@redux/views.slice';

import Topnav, { type propTypes } from './Topnav';

export default connect(
  (state: RootState) => ({
    auth: state.auth,
    app: state.app,
    system: state.system,
    systemStatus: selectSystemStatus(state),
    isOnboardingEnabled: state.views.gettingStarted?.isEnabled ?? false,
  }),
  {
    appStateReset,
    updateAccountContext,
    setTopnavViewProps,
    setWhatsNewDialog,
    setWhatsNewShowCtrl,
    logout,
    showModal,
    setViewState,
  },
)(Topnav as ComponentClass<InferProps<typeof propTypes>>);
