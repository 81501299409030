import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import history from '@/history';

function isLeftClickEvent(event) {
  return event.button === 0;
}

function isModifiedEvent(event) {
  return !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey);
}

class Link extends PureComponent {
  handleClick = event => {
    const { auth, useContext = true } = this.props;
    const { account } = auth;

    if (this.props.onClick) {
      this.props.onClick(event);
    }

    // Obtain the account context in order to qualify the changes to history
    // made by clicking the link (if useContext is `true`)
    const context =
      useContext && account.context?.accountname
        ? `/${encodeURIComponent(account.context?.accountname)}`
        : '';

    if (isModifiedEvent(event) || !isLeftClickEvent(event)) {
      return;
    }

    if (event.defaultPrevented === true) {
      return;
    }

    event.preventDefault();
    history.push(`${context}${this.props.to}`);
  };

  render() {
    const { useContext = true, auth, to, children, ...props } = this.props;
    const { account } = auth;

    // Obtain the account context in order to qualify the changes to history
    // made by clicking the link (if useContext is `true`)
    const context =
      useContext && account.context?.accountname
        ? `/${encodeURIComponent(account.context?.accountname)}`
        : '';

    return (
      <a href={`${context}${to}`} {...props} onClick={this.handleClick}>
        {children}
      </a>
    );
  }
}

export const propTypes = {
  className: PropTypes.string,
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  useContext: PropTypes.bool,
  onClick: PropTypes.func,
  auth: PropTypes.shape({
    account: PropTypes.shape({
      context: PropTypes.shape({
        accountname: PropTypes.string,
      }),
    }),
  }).isRequired,
};

Link.propTypes = propTypes;

Link.defaultProps = {
  onClick: null,
  useContext: true,
  className: undefined,
};

export default Link;
