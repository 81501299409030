import actions from './actions.json';

// The route metadata array provides the information for each route and asserts
// the order in which the routing component reads each routing rule.
//
// Note that this information does not contain any of the view component import
// statements that specify the base view associated with each route (as these
// get merged into the object by an operation inside the route manager (defined
// in `/routes/index.js`) so it can be safely imported elsewhere by the
// application as a standalone dataset without any creating any side-effects
// such as circular dependencies.
export default [
  {
    path: '',
    actions: actions.base,
    navbar: {
      name: 'base',
      title: 'Login',
      permissions: [],
      show: false,
    },
    redirect: {
      primary: { path: 'dashboard', perms: ['listImages', 'viewReports'] },
      secondary: { path: 'system', perms: ['createUser'] },
      tertiary: { path: 'artifacts/image', perms: ['listImages'] },
    },
  },
  {
    path: '/dashboard',
    actions: [...actions.base, ...actions.dashboard],
    navbar: {
      name: 'dashboard',
      title: 'Dashboard',
      icon: 'dashboard',
      color: 'teal',
      permissions: [
        {
          action: 'listImages',
          target: '*',
        },
        {
          action: 'viewReports',
          target: '*',
        },
      ],
    },
  },
  {
    path: '/applications',
    actions: [...actions.base, ...actions.applications],
    entitlement: 'applications',
    navbar: {
      name: 'applications',
      title: 'Applications',
      icon: 'th',
      color: 'teal',
      permissions: [
        {
          action: 'listImages',
          target: '*',
        },
        {
          action: 'listSources',
          target: '*',
        },
        {
          action: 'getApplication',
          target: '*',
        },
      ],
    },
  },
  {
    path: '/sboms',
    actions: [...actions.base],
    featureFlag: 'sbomManager',
    navbar: {
      name: 'sboms',
      title: 'SBOM Manager',
      icon: 'file archive',
      color: 'teal',
    },
  },
  {
    path: '/artifacts/image',
    actions: [...actions.base, ...actions['artifacts/image']],
    navbar: {
      name: 'artifacts',
      title: 'Images',
      icon: 'tags',
      color: 'teal',
      permissions: [
        {
          action: 'listImages',
          target: '*',
        },
        {
          action: 'getImage',
          target: '*',
        },
        {
          action: 'getActions',
          target: '*',
        },
      ],
    },
  },
  {
    path: '/artifacts/source',
    actions: [...actions.base, ...actions['artifacts/source']],
    navbar: {
      name: 'artifacts',
      title: 'Applications',
      permissions: [
        {
          action: 'listSources',
          target: '*',
        },
        {
          action: 'getSource',
          target: '*',
        },
      ],
      show: false,
    },
  },
  {
    path: '/inventory/kubernetes',
    actions: [...actions.base, ...actions['inventory/kubernetes']],
    entitlement: 'runtime_inventory',
    navbar: {
      name: 'inventory',
      title: 'Kubernetes',
      icon: 'kubeIcon',
      permissions: [
        {
          action: 'listImages',
          target: '*',
        },
        {
          action: 'listRuntimeInventories',
          target: '*',
        },
      ],
    },
  },
  {
    path: '/policy',
    actions: [...actions.base, ...actions.policy],
    navbar: {
      name: 'policy',
      title: 'Policies',
      icon: 'suitcase',
      color: 'teal',
      permissions: [
        {
          action: 'listPolicies',
          target: '*',
        },
      ],
    },
  },
  {
    path: '/reports',
    actions: [...actions.base, ...actions.reports],
    navbar: {
      name: 'reports',
      title: 'Reports',
      icon: 'list alternate outline',
      color: 'teal',
      permissions: [
        {
          action: 'listImages',
          target: '*',
        },
        {
          action: 'viewReports',
          target: '*',
        },
      ],
    },
  },
  {
    path: '/events',
    actions: [...actions.base, ...actions.events],
    navbar: {
      name: 'events',
      title: 'Events & Notifications',
      icon: 'bullhorn',
      color: 'teal',
      permissions: [
        {
          action: 'listEvents',
          target: '*',
        },
      ],
    },
  },
  {
    path: '/notifications',
    actions: [...actions.base, ...actions.notifications],
    navbar: {
      name: 'events',
      title: 'Events & Notifications',
      permissions: [
        {
          action: 'getNotificationEndpointConfiguration',
          target: '*',
        },
        {
          action: 'listNotificationEndpointConfigurations',
          target: '*',
        },
        {
          action: 'listNotificationEndpoints',
          target: '*',
        },
      ],
      show: false,
    },
  },
  {
    path: '/system',
    actions: [...actions.base, ...actions.system],
    navbar: {
      name: 'system',
      title: 'System',
      position: 'right',
      icon: 'setting',
      color: 'teal',
      permissions: [
        {
          action: 'createUser',
          target: '*',
        },
      ],
    },
  },
  // Wildcard routes, e.g. { path: '(.*)', ... } (must go last)
  {
    path: '(.*)',
    actions: [...actions.base],
  },
];
